.menu {
    width: 100px;
}

#menuToggle {
    display: block;
    position: absolute;
    top: 22px;
    right: 30px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    &:after {
        transition: .5s ease-in-out all;
        content: "Menu";
        position: relative;
        left: -3px;
        border-bottom: 1px solid transparent;
    }
    &:hover {
        &:after {
            border-bottom: 1px solid $primary;
        }
    }
}

#menuToggle input {
    display: block;
    width: 50px;
    height: 55px;
    position: absolute;
    top: -7px;
    left: -9px;
    &:after {}
    cursor: pointer;
    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */
    -webkit-touch-callout: none;
}


/*
 * Just a quick hamburger
 */

#menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}


/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */

#menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: white;
}


/*
 * But let's hide the middle one.
 */

#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}


/*
 * Ohyeah and the last one should go the other direction
 */

#menuToggle input:checked~span:nth-last-child(2) {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}


/*
 * Make this absolute positioned
 * at the top left of the screen
 */

#menu {
    position: absolute;
    width: 500px;
    margin: 35px 0 0 0;
    padding: 50px 50px 50px 20px;
    padding-top: 30px;
    right: -100px;
    height: calc(100vh - 84px);
    background: #2d2d32;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    >div {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        padding: 50px 15px;
    }
    @media screen and (max-width:1200px) {
        width: 320px;
        padding: 20px;
        right: -30px;
        margin-top: 45px;
    }
}

#menu li {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    width: 100%;
    color: rgb(255, 255, 255);
    display: flex;
    align-content: center;
    &:hover a {
        color: rgba( #fff, $alpha: 0.8);
    }
    & a {
        border-bottom: 1px solid transparent;
        border-left: unset;
    }
    &.active a {
        border-color: #a11313;
    }
    & i {
        transform: scale(0.75);
        display: flex;
        align-items: center;
        position: relative;
        right: -10px;
        top: 5px;
        &:hover {
            transition: 0.3s ease-out;
            cursor: pointer;
            transform: scale(0.75);
        }
        &.active {
            transition: 0.3s ease-out;
            transform: scale(0.82) rotate(45deg);
            opacity: 1;
            &~ul {
                max-height: auto;
            }
        }
    }
    &:hover {
        &:after {
            width: 80%;
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        width: 0;
        z-index: -1;
        -webkit-transition: all 300ms linear;
        transition: all 300ms linear;
        height: 100%;
        background: rgba(255, 255, 255, 0.071);
    }
    a {
        font-size: 18px;
        border-bottom: 1px solid transparent;
        display: flex;
        align-items: center;
        min-height: 40px;
        padding-bottom: 0;
        &:hover {
            text-decoration: none;
            border-color: #a11313;
        }
    }
}

#menu .sub-menu {
    transition: 0.75s;
    position: relative;
    max-height: 0;
    overflow: hidden;
    li a {
        font-size: 14px;
    }
    li:after {
        left: -120px;
    }
}


/*
 * And let's fade it in from the left
 */

#menuToggle input:checked~ul {
    transform: scale(1.0, 1.0);
    opacity: 1;
}