            $primary: #a11313;
            @import url('https://fonts.googleapis.com/css?family=Mukta:400,500,700&display=swap&subset=latin-ext');
            $font: 'Mukta',
            sans-serif;
            $transition: all 0.3s ease-in-out;
            html {
                position: relative;
                width: 100vw;
                height: 100vh;
                overflow-x: hidden;
                overflow-y: hidden;
                scroll-behavior: smooth;
            }
            
            body {
                position: relative;
                font-family: $font;
                overflow: hidden;
                width: 100%;
                scroll-behavior: smooth;
                height: calc(100%+40vh);
                p:last-child {
                    margin-bottom: 0;
                }
            }
            
            .text-primary {
                color: $primary!important;
            }
            
            h1,
            .h1,
            h2,
            .h2,
            h3,
            .h3,
            h4,
            .h4,
            h5,
            .h5 {
                font-family: $font;
            }
            
            strong {
                font-weight: 600;
            }
            
            .top-el {
                position: absolute!important;
                z-index: 10;
                &.logo {
                    width: 80%;
                    right: 30px;
                }
            }
            
            h1,
            .h1 {
                font-size: 26px;
                text-align: left;
                text-transform: uppercase;
                color: #000;
                font-weight: 800;
                float: left;
                width: 100%;
                letter-spacing: 2px;
                padding-bottom: 10px;
                line-height: 34px;
                @media screen and (max-width: 1200px) {
                    font-size: 22px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 2px;
                    width: 30px;
                    color: $primary;
                }
            }
            
            h2,
            .h2 {
                font-size: 24px;
                margin-bottom: 30px;
                @media screen and (max-width: 1200px) {
                    font-size: 20px!important;
                }
            }
            
            h3,
            .fullWidth {
                width: 100%;
            }
            
            .image-link {
                position: relative;
            }
            
            .image-link:hover:after {
                position: absolute;
                content: "";
                height: 20vh;
                width: 20vh;
                background: #000;
            }
            
            a {
                transition: $transition;
                color: #000;
                cursor: pointer;
                border-bottom: 1px solid transparent;
                &:hover {
                    border-bottom-color: #a11313;
                    color: rgba($color: #fff, $alpha: 0.8);
                    text-decoration: none;
                }
            }
            
            button {
                transition: $transition;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 60px;
                color: #fff;
                text-shadow: 2px 2px 1px #000;
                letter-spacing: 1.75px;
                width: 300px;
                background: rgba(0, 0, 0, 0.767);
                box-shadow: inset 0 -12px 8px 6px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                &:hover {
                    box-shadow: inset 0 -10px 8px 6px rgba(255, 255, 255, 0.28), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                    color: rgba(255, 255, 255, 0.75);
                    transform: scale(0.99) translateX(0.75px);
                    i.icon>svg {
                        fill: rgba(255, 255, 255, 0.75);
                    }
                }
                &:active {
                    transform: scale(0.98);
                }
                span {
                    position: relative;
                    padding: 0 20px;
                    flex: 75% 0 0;
                    text-align: center;
                    border-right: 1px solid #fff;
                }
                i.icon {
                    position: relative;
                    filter: drop-shadow(2.4px 2.4px 1px #000);
                    padding: 0 20px;
                    flex: 25% 0 0;
                    height: 33px;
                    width: 31px;
                    text-align: center;
                    ;
                    >svg {
                        height: inherit;
                        width: inherit;
                    }
                }
            }
            
            i.icon {
                font-size: 12px;
                opacity: 0.8;
                transition: $transition;
                height: 28px;
                width: 30px;
                >svg {
                    fill: #fff;
                    height: inherit;
                    width: inherit;
                }
            }
            
            ul.custom {
                li {
                    display: flex;
                    align-items: flex-start;
                    margin: 15px 0;
                    position: relative;
                    width: 100%;
                    margin-bottom: 30px;
                    padding-left: 30px;
                    font-size: 18px;
                    svg {
                        font-size: 100px;
                        fill: #a11313;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        position: absolute;
                        height: 18px;
                        width: 23px;
                    }
                }
                &.small li {
                    font-size: 16px;
                    margin-bottom: 15px;
                    svg {
                        height: 15px;
                        width: 20px;
                    }
                }
            }
            
            ul.custom.num {
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 1200px) {
                    display: block;
                    padding: 0;
                }
                li {
                    padding-left: 125px;
                    flex: 45% 1 0;
                    svg {
                        height: 120px;
                        width: 120px;
                        fill: #fff;
                        background: #a11313;
                        box-shadow: inset 0 -14px 30px 20px rgba(0, 0, 0, 0.8), inset 0 14px 12px 10px rgba(255, 255, 255, 0.27);
                        &:hover {
                            fill: #000;
                            background: #a11313;
                            box-shadow: inset 0 -14px 30px 20px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                        }
                    }
                }
            }
            
            .icon_ul_1 {
                transition: $transition;
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                padding: 0;
                li {
                    flex-basis: 33%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 30px;
                    svg {
                        transition: $transition;
                        height: 200px;
                        width: 215px;
                        margin-bottom: 15px;
                        padding: 15px;
                        font-weight: 250;
                        font-size: 70px;
                        fill: #fff;
                        fill: #fff;
                        border: 1px solid #000;
                        background: #a11313;
                        box-shadow: inset 0 -14px 30px 20px rgba(0, 0, 0, 0.8), inset 0 14px 12px 10px rgba(255, 255, 255, 0.27);
                        &:hover {
                            transition: $transition;
                            fill: #000;
                            background: #a11313;
                            box-shadow: inset 0 -14px 30px 20px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                        }
                    }
                }
            }
            
            .logo {
                img {
                    filter: drop-shadow(1.7px 1.7px 0.45px #fff);
                }
            }
            
            header {
                display: flex;
                align-items: center;
                background: rgb(45, 45, 50);
                color: white;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 1000;
                height: 12vh;
                img {
                    transform: scale(0.9);
                }
                @media screen and (max-width: 1200px) {
                    z-index: 100000;
                }
                a {
                    display: flex;
                    width: fit-content;
                    height: inherit;
                    align-items: center;
                    color: #fff;
                    border-left: 2px solid $primary;
                    i {
                        display: flex;
                        align-items: center;
                        height: 140%;
                        margin-right: 20px;
                        padding-left: 15px;
                        color: darken(white, 35%);
                    }
                }
                .bg-grey {
                    background: rgba(255, 255, 255, 0.09);
                }
                .col {
                    display: flex;
                    align-items: center;
                    height: 12vh;
                    &:first-of-type {
                        border: 0;
                    }
                    &:last-of-type {
                        border-left: unset;
                    }
                }
            }
            
            .top {
                position: absolute;
            }
            
            main {
                position: relative;
                padding-left: 70px;
                padding-top: 84px;
                h2 {
                    font-size: 24px;
                }
                @media screen and (max-width:767px) {
                    padding-left: 0px;
                }
            }
            
            aside {
                z-index: 100;
                width: 70px;
                height: calc(100vh - 84px);
                background: #000;
                position: fixed;
                top: 12vh;
                left: 0;
                @media screen and (max-width: 767px) {
                    display: none;
                }
                h2 {
                    color: white;
                    text-align: center;
                    z-index: 300;
                    position: relative;
                    top: 30px;
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 500;
                    line-height: 32px;
                    max-height: 50%;
                    padding: 15px 30px;
                    &::after {
                        z-index: 1;
                        position: absolute;
                        content: "";
                        width: 25px;
                        height: 60%;
                        background: #ffffff1e;
                        left: calc(50% - 11.5px);
                        bottom: 0;
                    }
                    &:before {
                        position: absolute;
                        content: "";
                        width: 2px;
                        height: 66px;
                        background: #a11313;
                        top: -5px;
                        left: 27%;
                    }
                }
            }
            
            section {
                display: flex;
                flex-direction: column;
                padding: 100px 0;
                @media screen and (max-width: 767px) {
                    margin-top: 100px;
                    padding: 0;
                }
            }
            
            .wrapper {
                height: fit-content;
            }
            
            .wrapper-container {
                position: relative;
                display: flex;
                flex-direction: column;
                height: 90vh;
            }
            
            .divider {
                margin: 100px 0 0 0;
                height: 2px;
                background: #a11313;
            }
            
            .images {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
            
            .img {
                position: relative;
                text-align: unset;
                overflow: hidden;
                height: 20vh;
                width: 17vw;
                margin-bottom: 15px;
                @media screen and (max-width: 1200px) {
                    width: auto;
                    max-width: 100%;
                    display: block;
                }
                >img {
                    transition: $transition;
                    position: relative;
                    width: inherit;
                    height: inherit;
                }
                a {
                    position: absolute;
                    transition: $transition;
                    z-index: 2;
                    height: inherit;
                    width: inherit;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    >span {
                        transition: $transition;
                        position: relative;
                        font-size: 26px;
                        color: #fff;
                        padding: 10px;
                        transform: translateX(-250%);
                        svg {
                            height: 8vh;
                            width: 5vw;
                            opacity: 0.85;
                            fill: #fff;
                        }
                    }
                    &:after {
                        transition: $transition;
                        left: 200%;
                        z-index: -1;
                        position: absolute;
                        content: "";
                        width: 17vw;
                        height: 20vh;
                        transform: skew(-45deg);
                        background: #a1131383;
                    }
                    &:before {
                        transition: $transition;
                        left: -200%;
                        position: absolute;
                        content: "";
                        background: rgba(0, 0, 0, 0.37);
                        transform: skew(-45deg);
                        width: 17vw;
                        height: 20vh;
                    }
                    &:hover {
                        span {
                            transform: translateY(0);
                        }
                        &:before {
                            transition: $transition;
                            left: -45%;
                        }
                        &:after {
                            transition: $transition;
                            left: 45%;
                        }
                        &+img {
                            transform: scale(1.15);
                        }
                    }
                }
                &-single {
                    width: 100%;
                    height: 45vh;
                    a>span {
                        transform: translateX(-500%);
                    }
                }
            }
            
            .image {
                position: fixed;
                top: 10vh;
                right: 0;
                @media screen and (max-width: 992px) {
                    position: static;
                }
            }
            
            .wrapper-image {
                top: 10vh;
                background-position: center;
                width: 100%;
                height: 90vh
            }
            
            #about .wrapper-image {
                background: url("../img/brueder.jpg");
                background-position: 33% 50%;
            }
            
            #heizung .wrapper-image {
                background: url("../img/Patynek_Feuer_Heizungen.jpg");
                background-size: cover;
            }
            
            #sanitaer .wrapper-image {
                background: url("../img/header_bad.jpg");
                background-size: cover;
                background-position: center;
            }
            
            #lueftung .wrapper-image {
                background: url("../img/lueftung.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            
            #karriere .wrapper-image {
                background: url("../img/karriere_header.jpg");
                background-size: cover;
                background-position: 65% 20%;
            }
            
            .p-box {
                position: relative;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            
            .p-box {
                >div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 30% 0 0;
                    text-align: center;
                    margin-bottom: 30px;
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #000);
                    padding: 15px;
                    color: #fff;
                    @media screen and (max-width: 1200px) {
                        flex: 100%;
                    }
                    >h2 {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #a11313;
                        font-size: 18px;
                        text-align: left;
                        >svg {
                            fill: #fff;
                            position: relative;
                            align-self: center;
                            height: 40px;
                            width: 40px;
                            transform: translateY(-10%);
                        }
                    }
                    >p {
                        text-align: left;
                    }
                }
            }
            
            .b-box {
                background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, .4));
                position: relative;
                flex-basis: 65%;
                >img {
                    position: absolute;
                    left: -10%;
                    top: -15%;
                    height: 130%;
                    width: 120%;
                }
                >h2 {
                    position: absolute;
                    width: 100%;
                    font-size: 16px;
                    &:nth-of-type(1) {
                        transform: rotate(-90deg) translate(-105px, -245px);
                    }
                    &:nth-of-type(2) {
                        bottom: -10px;
                        left: 80px;
                    }
                    &:nth-of-type(3) {
                        transform: rotate(-42deg) translate(-110px, 130px);
                    }
                }
            }
            
            .offer-box {
                width: 100%;
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                }
                .offer {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 30%;
                    flex: 30% 0 1;
                    @media screen and (max-width: 1200px) {
                        flex-basis: 100%;
                        width: 100%;
                        margin-bottom: 30px;
                    }
                    h2 {
                        border-bottom: 1px solid #a11313;
                        width: 80%;
                    }
                    h3 {
                        margin-top: 20px;
                        font-size: 18px;
                    }
                    a {
                        align-self: flex-start;
                        &:hover {
                            color: #000;
                            transform: scale(1.15);
                        }
                    }
                    svg {
                        position: relative;
                        left: 0;
                        transition: 0.4s ease-out;
                        height: 200px;
                        width: 100%;
                        padding: 15px;
                        background: #a11313;
                        box-shadow: inset 0 -14px 30px 20px rgba(0, 0, 0, 0.8), inset 0 14px 12px 10px rgba(255, 255, 255, 0.27);
                        fill: rgb(255, 255, 255);
                        @media screen and (max-width: 1200px) {
                            width: 80%;
                        }
                        &:hover {
                            fill: #000;
                            background: #a11313;
                            box-shadow: inset 0 -14px 30px 20px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                        }
                    }
                }
            }
            
            #timeline {
                position: relative;
                height: 100%;
                &::before {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 2px;
                    background: #a11313;
                }
                .container {
                    position: relative;
                    margin: 50px 0;
                    &::before {
                        position: absolute;
                        content: "";
                        height: 30%;
                        width: 20px;
                        border-radius: 100%;
                        background: #a11313;
                        top: 35%;
                        left: -8px;
                    }
                    & i {
                        font-size: 55px;
                    }
                }
            }
            
            #map {
                padding-top: 1vh;
                height: 92vh;
                width: 100%;
                >iframe {
                    height: inherit;
                    width: inherit;
                }
            }
            
            #map-modal p {
                font-size: 14px;
            }
            
            form {
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-top: 50px;
                .agb {
                    margin-bottom: 50px;
                    .error {
                        color: #a11313;
                    }
                }
                a {
                    margin: 20px 0;
                }
                label.error {
                    transition: $transition;
                    opacity: 1;
                    color: #a11313;
                    font-size: 13px;
                }
                 :hover {
                    color: rgb(87, 86, 86);
                }
                .form-item {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    flex: 45% 0 1;
                    min-height: max-content;
                    margin-bottom: 60px;
                    @media screen and (max-width: 1200px) {
                        flex: 100%;
                        margin-bottom: 40px;
                    }
                    span {
                        position: relative;
                    }
                    .form-item-input {
                        all: unset;
                        position: absolute;
                        border: unset;
                        border-bottom: 1px solid #000;
                        background: transparent;
                        z-index: 10;
                        @media screen and (max-width: 1200px) {
                            width: 100%;
                        }
                    }
                    &.fullWidth {
                        flex: 100% 1 0;
                        span {
                            transform: translateY(45px);
                            @media screen and (max-width: 1200px) {
                                transform: translateY(0px);
                            }
                        }
                        .form-item-input {}
                        label.error {
                            transition: unset;
                            transform: translateY(45px);
                        }
                        &.active {
                            span {
                                transform: translateY(-28px);
                            }
                        }
                    }
                    &.active {
                        span {
                            transform: translateY(-100%);
                            transition: $transition;
                            font-weight: bolder;
                        }
                    }
                }
            }
            
            .accordion-wrapper {
                .panel {
                    transition: all 0.4s ease-out;
                    max-height: 0;
                    overflow: hidden;
                }
                .accordion {
                    transition: 0.2s;
                    cursor: pointer;
                    padding: 18px;
                    width: 100%;
                    border: none;
                    text-align: left;
                    outline: none;
                    font-size: 15px;
                    transition: 0.4s;
                    &.active,
                    &:hover {
                        background-color: #ccc;
                    }
                }
                .active,
                .accordion:hover {
                    background-color: #ccc;
                }
            }
            
            footer {
                position: relative;
                color: #fff;
                background: #2D2D32;
                margin-left: 70px;
                width: 100vw;
                z-index: 10000;
                padding: 50px 50px;
                @media screen and (max-width: 767px) {
                    margin-left: 0px;
                }
                a {
                    color: #fff;
                    &:hover {
                        color: rgba(rgb(255, 255, 255), $alpha: 0.8);
                    }
                }
                .col {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    max-height: inherit;
                    justify-content: flex-start;
                    & .logo {
                        width: 50%;
                        img {
                            width: 100%;
                        }
                        margin-bottom: 7px;
                    }
                    ul {
                        padding: unset;
                        display: flex;
                        flex-direction: column;
                    }
                    li {
                        all: unset;
                        ;
                    }
                    &:nth-last-of-type(2) {
                        display: block;
                        line-height: 40px;
                        >* {
                            width: calc(fit-content + 30px);
                            text-align: center;
                            position: relative;
                            margin: 0 7px 20px 7px;
                            &:after {
                                position: absolute;
                                content: "";
                                height: 25px;
                                top: 0;
                                right: -10px;
                                width: 4px;
                                background: #921212a9;
                            }
                            &:last-of-type:after,
                            &:nth-last-of-type(4):after {
                                all: unset;
                            }
                        }
                    }
                    &:last-of-type>a {
                        width: fit-content;
                    }
                }
            }
            
            .navigator {
                display: flex;
                flex-direction: column;
                z-index: 2;
                position: fixed;
                height: 8vh;
                width: 35vw;
                background: #2D2D32;
                right: 15px;
                bottom: 0;
                @media screen and (max-width: 767px) {
                    display: none;
                }
                * {
                    transition: 0.2s ease-in-out;
                }
                .sControll {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    padding: 8px 10px;
                    justify-content: space-evenly;
                    height: 30px;
                    color: #fff;
                    ul {
                        height: inherit;
                        flex: 60% 1 0;
                        li {
                            all: unset;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        }
                    }
                    i {
                        position: relative;
                        transform: scale(0.8) translateY(-4px);
                        cursor: pointer;
                        &:hover {
                            opacity: 1;
                            transform: scale(0.83) translateY(-5px);
                        }
                    }
                }
                .posBar {
                    position: relative;
                    top: 10px;
                    height: 5px;
                    background: linear-gradient(90deg, #a11313, #000 33%);
                }
            }
            /********************** SLIDER ***********************/
            
            article {
                >.img {
                    position: absolute;
                    z-index: 3;
                    width: 55%;
                    height: 30%;
                    right: 5.55%;
                    top: 4%;
                    transform: scale(1.18);
                    img {
                        filter: drop-shadow(2.5px 2.5px 0.1px #fff);
                    }
                }
            }
            
            svg {
                display: block;
                overflow: visible;
            }
            
            .slider-container {
                position: relative;
                height: calc(100vh - 84px);
                user-select: none;
                cursor: grab;
            }
            
            .slider-control {
                text-align: center;
                padding: 12px 0;
                font-size: 14px;
                z-index: 2;
                position: absolute;
                bottom: 0;
                width: 12%;
                transition: opacity 0.3s;
                will-change: opacity;
                color: #fff;
                background: #2D2D32;
                &:not(.inactive):hover {
                    opacity: 1;
                    cursor: pointer;
                }
                &.left {
                    left: 70%;
                    border-bottom: 2px solid transparent;
                    &:hover {
                        border-color: #a11313;
                    }
                }
                &.right {
                    left: 85%;
                    border-bottom: 1px solid transparent;
                    &:hover {
                        border-color: #a11313;
                    }
                }
            }
            
            .slider-pagi {
                display: flex;
                flex-direction: column;
                position: absolute;
                z-index: 50;
                right: 2rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0;
                list-style-type: none;
                &__elem {
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin: 0.5rem 0;
                    border: 2px solid #fff;
                    cursor: pointer;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 60%;
                        height: 60%;
                        background: #fff;
                        transition: transform 0.3s;
                        transform: translate(-50%, -50%) scale(0);
                    }
                    &:hover:before {
                        opacity: 0.6;
                    }
                    &.active:before,
                    &:hover:before {
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }
            
            .slider {
                z-index: 15;
                position: relative;
                height: 100%;
                width: 100%;
                &.animating {
                    transition: transform 0.5s;
                    will-change: transform;
                    .slide__bg {
                        transition: transform 0.5s;
                        will-change: transform;
                    }
                }
            }
            
            .slide {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                &.active {
                    .slide__overlay,
                    .slide__text {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
                &__bg {
                    position: absolute;
                    top: 0;
                    left: -50%;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    will-change: transform;
                }
                &:nth-child(1) {
                    left: 0;
                    .slide__bg {
                        left: 0;
                        background-image: url('../img/brueder.jpg');
                        background-position-x: 33vw;
                        background-size: cover;
                    }
                }
                &:nth-child(2) {
                    left: 100%;
                    .slide__bg {
                        left: -50%;
                        background-image: url('../img/Patynek_Feuer_Heizungen.jpg');
                    }
                }
                &:nth-child(3) {
                    left: 200%;
                    .slide__bg {
                        left: -100%;
                        background-image: url('../img/Patynek_Badezimmer_Heizungen.jpg');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: 0 0;
                    }
                }
                &:nth-child(4) {
                    left: 300%;
                    .slide__bg {
                        left: -150%;
                        background-image: url('../img/heat.jpg');
                        background-size: 150% 150%;
                        background-position: 30vw;
                    }
                }
                &__content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                &__overlay {
                    position: absolute;
                    z-index: 10;
                    bottom: 0;
                    left: 0;
                    height: 100%;
                    min-height: 810px;
                    transition: transform 0.5s 0.5s, opacity 0.2s 0.5s;
                    will-change: transform, opacity;
                    transform: translate3d(-20%, 0, 0);
                    opacity: 0;
                    path {
                        fill: rgb(255, 255, 255);
                    }
                }
                &__text {
                    position: absolute;
                    @media screen and (max-width: 767px) {
                        width: 90%;
                    }
                    width: 38%;
                    bottom: 6%;
                    left: 5%;
                    z-index: 10;
                    transition: transform 0.5s 0.8s,
                    opacity 0.5s 0.8s;
                    will-change: transform,
                    opacity;
                    transform: translateY(-50%);
                    opacity: 0;
                    &-heading {
                        position: relative;
                        font-size: 32px;
                        margin-bottom: 2rem;
                        text-transform: uppercase;
                        &:after {
                            position: absolute;
                            content: "";
                            width: 20%;
                            height: 4px;
                            background: #a11313;
                            bottom: 150%;
                            left: 0;
                        }
                        &:before {
                            position: absolute;
                            content: "";
                            width: 20%;
                            height: 4px;
                            background: #a11313;
                            bottom: 150%;
                            left: 0;
                        }
                    }
                    &-icon {
                        transition: 0.4s;
                        position: relative;
                        height: 180px;
                        width: 180px;
                        font-weight: 300;
                        margin-bottom: 2rem;
                        padding: 20px;
                        fill: #fff;
                        background: #a11313;
                        box-shadow: inset 0 -14px 30px 20px rgba(0, 0, 0, 0.8), inset 0 14px 12px 10px rgba(255, 255, 255, 0.27);
                        &:hover {
                            fill: #000;
                            background: #a11313;
                            box-shadow: inset 0 -14px 30px 20px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                        }
                    }
                    &-desc {
                        font-size: 18px;
                        margin-bottom: 1.5rem;
                    }
                    &-link {
                        transition: $transition;
                        z-index: 5;
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        position: relative;
                        padding-bottom: 0.1rem;
                        cursor: pointer;
                        font-size: 16px;
                        perspective: 1000px;
                        border-bottom: 1px solid transparent;
                        &:hover {
                            color: #000;
                            transition: 0.3s;
                            border-color: #a11313;
                            ;
                        }
                        .icon {
                            margin-left: 5px;
                            font-weight: 800;
                            svg {
                                fill: #a11313;
                            }
                        }
                        &:hover {
                            transform: scale(1.075);
                        }
                    }
                }
            }
            
            .svg {
                transition: $transition;
                margin-left: 10px;
                height: 60px;
                width: 60px;
            }
            /*******************TIMELINE***************/
            
            $primary: #a11313;
            $dark-primary: #921212;
            $light-primary: #0a02025d;
            $text: #FFFFFF;
            $primary-text: #000000;
            $secondary-text: #757575;
            $accent: #da3f3f;
            .timeline {
                .svg_icon {
                    &:hover {
                        fill: #000;
                        background: #a11313;
                        box-shadow: inset 0 -14px 30px 20px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                    }
                    transition: 0.2s ease-in;
                    position: relative;
                    height: 180px;
                    width: 180px;
                    left: 50%;
                    padding: 20px;
                    transform:translateX(-50%);
                    background: #a11313;
                    box-shadow: inset 0 -14px 30px 20px rgba(0,
                    0,
                    0,
                    0.8),
                    inset 0 14px 12px 10px rgba(255,
                    255,
                    255,
                    0.27);
                }
                position: relative;
                &::before {
                    content: '';
                    background: $light-primary;
                    width: 5px;
                    height: 95%;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            
            .timeline-item {
                width: 100%;
                margin-bottom: 70px;
                &:nth-child(even) {
                    .timeline-content {
                        float: right;
                        .date {
                            right: auto;
                            left: 0;
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            border-style: solid;
                            width: 0;
                            height: 0;
                            top: 30px;
                            left: -15px;
                            border-width: 10px 15px 10px 0;
                            border-color: transparent #f5f5f5 transparent transparent;
                        }
                    }
                }
                &::after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
            
            .timeline-content {
                position: relative;
                width: 45%;
                padding: 10px 30px;
                border-radius: 4px;
                background: #f5f5f5;
                box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
                &::after {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    width: 0;
                    height: 0;
                    top: 30px;
                    right: -15px;
                    border-width: 10px 0 10px 15px;
                    border-color: transparent transparent transparent #f5f5f5;
                }
            }
            
            .timeline-img {
                width: 30px;
                height: 30px;
                background: $primary;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                margin-top: 25px;
                margin-left: -15px;
            }
            
            .timeline-card {
                padding: 0!important;
                p {
                    padding: 0 20px;
                }
                a {
                    margin-left: 20px;
                }
            }
            
            .timeline-item {
                .timeline-img-header {
                    transition: $transition;
                    display: flex;
                    align-items: center;
                    background: rgba(0, 0, 0, 0.767);
                    box-shadow: inset 0 -12px 8px 6px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                    fill: #fff;
                    background-size: cover;
                }
                &:hover .timeline-img-header {
                    transition: $transition;
                    box-shadow: inset 0 -10px 8px 6px rgba(255, 255, 255, 0.4), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                }
            }
            
            .timeline-img-header {
                height: 200px;
                position: relative;
                margin-bottom: 20px;
                h2 {
                    color: $text;
                    position: absolute;
                    bottom: 5px;
                    left: 20px;
                }
            }
            
            blockquote {
                margin-top: 30px;
                color: $secondary-text;
                border-left-color: $primary;
                padding: 0 20px;
            }
            
            .date {
                background: #a11313;
                display: inline-block;
                color: $text;
                padding: 10px;
                position: absolute;
                top: 0;
                right: 0;
            }
            
            @media screen and (max-width: 768px) {
                .timeline {
                    &::before {
                        left: 50px;
                    }
                    .timeline-img {
                        left: 50px;
                    }
                    .timeline-content {
                        max-width: 100%;
                        width: auto;
                        margin-left: 70px;
                    }
                    .timeline-item {
                        &:nth-child(even) {
                            .timeline-content {
                                float: none;
                            }
                        }
                        &:nth-child(odd) {
                            .timeline-content {
                                &::after {
                                    content: '';
                                    position: absolute;
                                    border-style: solid;
                                    width: 0;
                                    height: 0;
                                    top: 30px;
                                    left: -15px;
                                    border-width: 10px 15px 10px 0;
                                    border-color: transparent #f5f5f5 transparent transparent;
                                }
                            }
                        }
                    }
                }
            }
            
            .icon_ul {
                @media screen and (max-width: 767px) {
                    padding: 0;
                }
                li {
                    transition: $transition;
                    position: relative;
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    padding-left: 160px;
                    margin-bottom: 70px;
                    @media screen and (max-width: 767px) {
                        padding: 0;
                        margin-bottom: 20px;
                    }
                    svg {
                        @media screen and (max-width: 767px) {
                            position: static;
                            margin-bottom: 10px;
                        }
                        transition: $transition;
                        position: absolute;
                        left: 0;
                        height: 140px;
                        width: 140px;
                        border: 1px solid #000;
                        fill: #fff;
                        background: #a11313;
                        box-shadow: inset 0 -14px 30px 20px rgba(0,
                        0,
                        0,
                        0.8),
                        inset 0 14px 12px 10px rgba(255,
                        255,
                        255,
                        0.27);
                        padding: 15px;
                        &:hover {
                            fill: #000;
                            background: #a11313;
                            box-shadow: inset 0 -14px 30px 20px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                        }
                    }
                    h3 {
                        position: relative;
                        height: fit-content;
                        font-size: 20px;
                    }
                    p {
                        float: left;
                        position: relative;
                    }
                }
            }
            
            .job-box {
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 1200px) {
                    display: block;
                }
                .job {
                    display: flex;
                    padding: 15px;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    width: 48%;
                    border: 1px solid #000;
                    background: rgba(0, 0, 0, 0.767);
                    box-shadow: inset 0 -12px 8px 6px rgba(255, 255, 255, 0.22), inset 0 14px 13px 12px rgba(0, 0, 0, 0.52);
                    color: #fff;
                    @media screen and (max-width: 1200px) {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    h2,
                    h5 {
                        font-size: 28px;
                        border-bottom: 1px solid #a11313;
                    }
                    h5 {
                        font-size: 24px;
                        padding: 3px 0;
                        display: flex;
                        width: 80%;
                        position: relative;
                        text-align: center;
                        justify-content: space-between;
                        align-items: flex-end;
                        >.svg {
                            flex: 20% 0 0;
                            fill: #fff;
                            ;
                        }
                    }
                }
            }
            
            $bgColor:#000000;
            .preloader {
                padding-top: 50vh;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 50000000;
                background: $bgColor;
                font: $font;
                -webkit-font-smoothing: antialiased;
                >* {
                    transform: scale(4);
                }
            }
            
            #preloader div {
                color: #fff;
                margin: 5px 0;
                text-transform: uppercase;
                text-align: center;
                font-family: "Arial", sans-serif;
                font-size: 10px;
                letter-spacing: 2px;
            }
            
            #preloader>div:first-of-type {
                margin-top: -150px;
            }
            
            .preloader-1 .line {
                width: 3px;
                height: 12px;
                background: #fff;
                margin: 0 3px;
                display: inline-block;
                animation: opacity-1 1000ms infinite ease-in-out;
            }
            
            .preloader-2 .line {
                width: 3px;
                height: 12px;
                background: #fff;
                margin: 0 3px;
                display: inline-block;
                animation: opacity-2 1000ms infinite ease-in-out;
            }
            
            .preloader-1 .line-1,
            .preloader-2 .line-1 {
                animation-delay: 800ms;
            }
            
            .preloader-1 .line-2,
            .preloader-2 .line-2 {
                animation-delay: 600ms;
            }
            
            .preloader-1 .line-3,
            .preloader-2 .line-3 {
                animation-delay: 400ms;
            }
            
            .preloader-1 .line-4,
            .preloader-2 .line-4 {
                animation-delay: 200ms;
            }
            
            .preloader-1 .line-6,
            .preloader-2 .line-6 {
                animation-delay: 200ms;
            }
            
            .preloader-1 .line-7,
            .preloader-2 .line-7 {
                animation-delay: 400ms;
            }
            
            .preloader-1 .line-8,
            .preloader-2 .line-8 {
                animation-delay: 600ms;
            }
            
            .preloader-1 .line-9,
            .preloader-2 .line-9 {
                animation-delay: 800ms;
            }
            
            @keyframes opacity-1 {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            
            @keyframes opacity-2 {
                0% {
                    opacity: 1;
                    height: 15px;
                }
                50% {
                    opacity: 0;
                    height: 12px;
                }
                100% {
                    opacity: 1;
                    height: 15px;
                }
            }
            
            .popup-container {
                position: absolute;
                height: 100vh;
                width: 100vw;
                background: #000;
            }
            
            .img-popup {
                transition: $transition;
                &:hover {
                    transition: $transition;
                    img {
                        transition: $transition;
                        transform: scale(0.9) skewX(-4deg) translateX(-5px);
                    }
                }
            }
            
            .mfp-with-zoom .mfp-container,
            .mfp-with-zoom.mfp-bg {
                opacity: 0;
                -webkit-backface-visibility: hidden;
                /* ideally, transition speed should match zoom duration */
                -webkit-transition: all 0.3s ease-out;
                -moz-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }
            
            .mfp-with-zoom.mfp-ready .mfp-container {
                opacity: 1;
            }
            
            .mfp-with-zoom.mfp-ready.mfp-bg {
                opacity: 0.8;
            }
            
            .mfp-with-zoom.mfp-removing .mfp-container,
            .mfp-with-zoom.mfp-removing.mfp-bg {
                opacity: 0;
            }
            
            .pattern {
                position: absolute;
                height: 100vh;
                z-index: 10;
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                background: rgba(0, 0, 0, 0.37);
                &-home {
                    background: rgba(0, 0, 0, 0.72);
                }
                >span {
                    text-align: center;
                    position: relative;
                    width: 1.22px;
                    background: rgba(255, 255, 255, 0.22);
                    &:nth-of-type(1):before {
                        animation-delay: 8s;
                    }
                    &:nth-of-type(2):before {
                        animation-delay: 1.8s;
                        height: 270px;
                    }
                    &:nth-of-type(3):before {
                        animation-delay: 5s;
                        height: 500px;
                    }
                    &:nth-of-type(4):before {
                        animation-delay: 0.2s;
                    }
                    &:before {
                        animation-name: down;
                        animation-duration: 4s;
                        animation-iteration-count: infinite;
                        animation-direction: alternate-reverse;
                        position: absolute;
                        content: "";
                        width: 2px;
                        height: 150px;
                        background: rgba(255, 255, 255, 0.3);
                        border-radius: 100%;
                    }
                }
            }
            
            @keyframes down {
                from {
                    top: -20%;
                }
                to {
                    top: 100%;
                }
            }
            
            @import "responsive";
            @import "menu";
            @import "subpage";