
.wrapper {
    
    background: #fff;
    &-container {
        height:100%;
        padding:0 100px;
        @media screen and (max-width:767px) {
            padding: 0 15px;
        }

        &-start{
      
            padding:0;
        }

    }
    &-image {
        background: url("../img/heizung.jpg");
        background-attachment: fixed;
        background-position: center;
        width: 100%;
        height: 100vh;

    }
}